// import React, { useState, useEffect } from "react";
// import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";
// import {Box, Typography} from "@mui/material";
// import MDBox from "../../components/MDBox";
// import MDTypography from "../../components/MDTypography";
// import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
// import axios from "axios";
// import { isTokenExpired, logout } from '../../utils/authUtils';
// import { enqueueSnackbar } from "notistack";

// function Overview() {
//   const [userData, setUserData] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const user_id = localStorage.getItem("user_id");
//   const authToken = localStorage.getItem("token");
//   const role = localStorage.getItem("role");

//   useEffect(() => {
//     const fetchUserData = async () => {
//       if (isTokenExpired(authToken)) {
//         enqueueSnackbar("Login is expired, Sign-in again to continue", {variant: "error"});
//         setTimeout(() => {
//             logout();
//         }, 1000); 
//       }
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_BASEURL}/chamber/${user_id}`,
//           {
//             headers: {
//               Authorization: `Bearer ${authToken}`,
//             },
//           }
//         );

//         if (response.data.success) {
//           setUserData(response.data.data);
//           setLoading(false);
//         }
//       } catch (error) {
//         console.error("Error fetching user data:", error);
//       }
//     };
//     setLoading(false);
//     fetchUserData();
//   }, [user_id, authToken]);

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (!userData) {
//     return <div>Error fetching user data</div>;
//   }

//   const { first_name, last_name, email } = userData;

//   return (
//     <DashboardLayout>
//       <DashboardNavbar />
//       <MDBox pt={3} pb={3} {...({} as any)}>
//         <Grid container spacing={6}>
//           <Grid item xs={12}>
//             <Card>
//               <Box pt={3}>
//                 <Box
//                   display="flex"
//                   justifyContent="space-between"
//                   alignItems="center"
//                   px={3}
//                   py={2}
//                 >
//                   <Typography
//                     variant="h6"
//                     color="black"
//                     sx={{ ml: 1, mb: 2 }}
//                   >
//                   My Profile
//                   </Typography>
//               </Box>
//               <MDBox pt={3} px={2} {...({} as any)}>
//                 <MDBox mb={3} mr={5}{...({} as any)}>
//                   <MDTypography variant="outline" {...({} as any)}>Name: {first_name} {last_name}</MDTypography>
//                 </MDBox>
//                 <MDBox mb={3}{...({} as any)}>
//                   <MDTypography variant="outline" {...({} as any)}>Role: {role}</MDTypography>
//                 </MDBox>
//                 <MDBox mb={5} {...({} as any)}>
//                   <MDTypography variant="outline" {...({} as any)}>Email: {email}</MDTypography>
//                 </MDBox>
//               </MDBox>
//               </Box>
//             </Card>
//           </Grid>
//         </Grid>
//       </MDBox>
//     </DashboardLayout>
//   );
// }

// export default Overview;

import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Avatar from "@mui/material/Avatar";
import { Person, Email, Work } from "@mui/icons-material";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import axios from "axios";
import { isTokenExpired, logout } from '../../utils/authUtils';
import { enqueueSnackbar } from "notistack";
import { Watermark } from 'antd';

import Chamber_SaaS_Logo_Transparent from '../../assets/Chamber_SaaS_Logo_Transparent.png';
import Grey_dark from '../../assets/Chamber_SaaS_Logo_Grey_Dark.png';
// import Grey_light from '../../../assets/Chamber_SaaS_Logo_Grey_Light.png';

function Overview() {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const user_id = localStorage.getItem("user_id");
  const authToken = localStorage.getItem("token");
  const role = localStorage.getItem("role");

  useEffect(() => {
    const fetchUserData = async () => {
      if (isTokenExpired(authToken)) {
        enqueueSnackbar("Login is expired, Sign-in again to continue", { variant: "error" });
        setTimeout(() => {
          logout();
        }, 1000);
        return;
      }
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASEURL}/chamber/${user_id}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        if (response.data.success) {
          setUserData(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [user_id, authToken]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
        <CircularProgress size={50} />
      </Box>
    );
  }

  if (!userData) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
        <Typography variant="h6" color="error">
          Error fetching user data
        </Typography>
      </Box>
    );
  }

  const { first_name, last_name, email } = userData;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* <Watermark
        height={256}
        width={256}
        // height={512}
        // width={512}
        image={Grey_dark}
        zIndex={-1}
        gap={[1000, 1000]}
      // style={{opacity:"0.5"}}
      > */}
      <MDBox pt={3} pb={3} {...({} as any)} style={{height:"80vh"}}>
      <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${Chamber_SaaS_Logo_Transparent})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            opacity: 0.7, // Set opacity for background image
            zIndex: -1 // Ensure background is behind the content
          }}
        />
        <Grid container spacing={6} justifyContent="center">
          <Grid item xs={12} 
          md={8} 
          lg={6}
          >
            <Card sx={{ p: 3, borderRadius: 4, boxShadow: 5 }}>
              <Box display="flex" alignItems="center" mb={3}>
                <Avatar sx={{ bgcolor: "#6c5ce7", width: 56, height: 56, mr: 2 }}>
                  {first_name[0]}{last_name[0]}
                </Avatar>
                <Typography variant="h5" fontWeight="bold">
                  My Profile
                </Typography>
              </Box>
              <MDBox pt={2} px={2} {...({} as any)}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Box display="flex" alignItems="center">
                      <Person sx={{ color: "gray", mr: 1 }} />
                      <MDTypography variant="body1" {...({} as any)}>
                        <strong>Name:</strong> {first_name} {last_name}
                      </MDTypography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box display="flex" alignItems="center">
                      <Work sx={{ color: "gray", mr: 1 }} />
                      <MDTypography variant="body1" {...({} as any)}>
                        <strong>Role:</strong> {role}
                      </MDTypography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center">
                      <Email sx={{ color: "gray", mr: 1 }} />
                      <MDTypography variant="body1" {...({} as any)}>
                        <strong>Email:</strong> {email}
                      </MDTypography>
                    </Box>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* </Watermark> */}
    </DashboardLayout>
  );
}

export default Overview;

